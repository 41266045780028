@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");





.occ-table th,
.occ-table td {
  padding: 20px;
  text-align: center;
  transition: 0.3s ease;
}
