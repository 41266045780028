.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #fcd3d3 !important;
  border-radius: 50px;
  border: 0 !important;
  font-weight: 600;
}

.nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  background-color: #fff3f3 !important;
  border-radius: 50px !important;
  border: 0 !important;
  color: #495057;
  margin-right: 10px;
}

.nav-tabs {
  border-bottom: 0 !important;
}

.rdt_TableCol,
.rdt_TableCell {
  font-size: 14px !important;
}

@media screen and (max-width: 480px) {
  .rev-text {
    font-size: 20px;
  }

  #uncontrolled-tab-example-tab-chartCase,
  #uncontrolled-tab-example-tab-chartRevenue {
    font-size: 14px;
  }

  .filter-text {
    font-size: 10px;
  }

  .rdt input {
    max-width: 80% !important;
    display: flex !important;
    margin: auto;
  }

  .cut-text {
    display: flex;
    font-size: 14px;
    justify-content: center;
  }

  .total-text {
    font-size: 14px;
  }
}

@media screen and (min-width: 481px) and (max-width: 991px) {
  .rev-text {
    font-size: 26px;
  }
}

@media screen and (min-width: 801px) and (max-width: 991px) {
  .filter-tabs {
    margin-top: 40px;
  }

  .datatable {
    margin-top: 60px;
  }
}

/* .datatable {
  margin-top: 80px;
}

@media screen and (max-width: 768px) {
  .datatable {
    margin-top: 100px;
  }

  .cut-text {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .datatable {
    margin-top: 200px;
  }

  .cut-text {
    display: none;
  }
}

.form-group {
  margin-bottom: 5px !important;
} */
