@media screen and (max-width: 480px) {
  .head {
    font-size: 20px;
  }

  .sales-head {
    font-size: 16px;
  }

  .occ-head {
    font-size: 16px;
  }

  .rev-col {
    min-height: auto;
  }

  .revenue-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 481px) and (max-width: 991px) {
  .head {
    font-size: 26px;
  }
}
