html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-link {
  color: #292e448c !important;
}

.menu-link:hover {
  color: #292e44 !important;
  background-color: transparent !important;
}

.btn-eye {
  color: #4e545a !important;
}

.btn-eye:hover {
  color: #212529 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 900px !important;
    margin: 1.75rem auto;
  }
}

@media (min-width: 800px) {
  .filter-tabs {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 800px) {
  .tab-content {
    margin-top: 30px;
  }
  .filter-tabs {
    position: absolute;
    top: 50px;
    z-index: 100;
  }
}

@media (max-width: 320px) {
  .revenue-tab .nav-link {
    font-size: 14px;
  }
}
